@use 'sass:color';

/* COLORS */
$primary-color: #0075b1;
$secondary-color: #f5f7fb;
$text-dark: #495057;
$text-light: #6f6f6f;
$text-green: #0b7c2f;
$progress-background: lighten($primary-color, 30%);
$border-color: rgba(0,40,100,.12);
$box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
$black: #000000;
$white: #FFFFFF;

/* FONTS */
$Text: 'UOLText';
$TextLight: 'UOLTextLight';
$TextBold: 'UOLTextBold';
