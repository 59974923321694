@import "variables.scss";

#home{
  padding: 10px 0 20px;

  .user{
    .welcome {
      padding: 0;
      margin: 0;
      margin-top: 2rem;
    }
  }

  .product-list{
    border: 1px solid $border-color;
    box-shadow: $box-shadow;
    position: relative;
    width: 100%;
    background-color: $white;

    .product-list-header{
      padding: 16px;
      display: flex;
      min-height: 3.5rem;
      align-items: center;
      border-bottom: 1px solid $border-color;
    }

  }
}