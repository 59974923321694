@import "variables.scss";

.not-found{

  .message{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30rem;

    .icon{
      font-size: 70px;
      color: $text-light;
    }

    h3{
      font-size: 20px;
    }
  }

}