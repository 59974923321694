@import "variables.scss";

#navbar{
  background-color: #FFFFFF;
  border-bottom: 1px solid $border-color;
  box-shadow: none;

  .header{
    padding: 1rem 0;
  }

  img{

    &.logo{
      max-width: 250px;
    }

  }

  .wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    justify-content: center;
    width: 100%;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .logout{
    border: 1px solid $primary-color;
    color: $primary-color;

    &:hover{
      background-color: $primary-color;
      border-color: $primary-color;
      color: $white;
    }
  }

}