@import 'variables.scss';

#tutorials{
  width: 100%;

  .card{
    box-shadow: $box-shadow;
    border: 1px solid $border-color;
    border-radius: 3px;

    h2{
      font-size: 20px;
    }

    .bar{
      position: absolute;
      top: 0px;
      left: -1px;
      right: -1px;
      height: 3px;
      border-radius: 3px 3px 0 0;
      background: $primary-color;
    }
  }

}