@import "variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  direction: ltr;
}

body{
  position: relative;
  background-color: $secondary-color;
}

#root{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;

  h1, h2, h3{
    font-family: $Text;
    color: $text-dark;
  }

  h4, h5, h6, p, i{
    font-family: $Text;
    color: $text-light;
  }

  b, strong{
    font-family: $TextBold;
    color: $text-dark;
  }

  .page-main{
    flex: 1 1 auto;
  }

  .loading{
    display: block;
    margin: 50px auto;
    color: $primary-color;
  }

  .alert{
    margin: 10px 0 0;
    border: 1px solid rgba(0, 40, 100, 0.12);
  }

  .progress {
    background-color: $progress-background;

    div{
      background-color: $primary-color;
    }
  }

  .inscriptions-error{
    display: block;
    text-align: center;
    margin: 50px auto;

    .icon{
      font-size: 50px;
      color: $text-light;
    }

    small{
      color: $text-dark;
      font-size: 12px;
    }
  }

}