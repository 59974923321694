@import "variables.scss";

#subscribes{
  width: 100%;
  margin-top: 20px;

  .product{
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: $white;
    background-clip: initial;
    border: 1px solid $border-color;
    border-radius: 3px;
    box-shadow: $box-shadow;
    position: relative;
    margin-bottom: 1.5rem;
    width: 100%;

    .product-status{
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      height: 3px;
      border-radius: 3px 3px 0 0;
      background: $primary-color;
    }

    .product-body{
      flex: 1 1 auto;
      margin: 0;
      padding: 1.5rem;
      position: relative;

      .product-name{
        font-size: .875rem;
        text-transform: uppercase;
        text-align: center;
        font-weight: 600;
        letter-spacing: .05em;
        margin: 0 0 .5rem;
      }

      .product-details{
        margin: 20px;

        li{
          list-style: none;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 5px;

          p{
            font-size: 12px;
            color: $text-green;
          }

          .icon{
            color: $text-green;
            font-size: 14px;
            margin-right: 10px;
          }

        }

      }

      .product-actions{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        button{
          color: $primary-color;
          margin: 0 5px;
          text-decoration: none;
          padding: 8px;
          border-radius: 2px;
          border: 1px solid $primary-color;

          &:hover{
            background-color: $primary-color;
            color: $white;
          }
        }
      }

    }

  }

}