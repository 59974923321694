@import "variables.scss";

#footer{
  background-color: $white;
  padding: 1.25rem 0;
  border-top: 1px solid rgba(0,40,100,.12);

  .container{

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    @media(max-width: 600px){
      flex-direction: column;
    }

    p{
      font-size: 10px;
      color: $text-light;
      text-align: center;

      @media(max-width: 600px){
        &.date{
          margin-bottom: 10px;
        }
      }
    }

  }

}